import React from 'react';
import { Row, Col, Card, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../utils/fetch'
import { AlertConfirm3 } from '../../../components/alert'
import { connect } from 'react-redux'
import { loadingAction } from '../../../redux/actions';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { formatDate } from '../../../utils/formatDate';
import DataGridForm from '../../../components/common/DataGridForm';
import { copyToClickBoard } from '../../../utils/slug';

function ListaKit({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)

    React.useEffect(() => {
        getContenido()

    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('kit-bienvenidas?_eliminado=false', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    const handleDragEnd = async (e) => {
        if (!e.destination) return;
        let tempData = Array.from(contenido);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        tempData.map((item, indice) => item.orden = indice)
        setContenido(tempData);
        await ordenContenido(tempData)
    };


    const ordenContenido = async (e) => {

        try {
            isLoadingAction(true)

            let request = []
            e.forEach(async (item, indice) => {
                const params = {
                    orden: indice
                }
                request.push(Fetch('kit-bienvenidas/' + item.id, 'put', params))
            })

            Promise.all(request).then(response => { isLoadingAction(false) })

        } catch (error) {
            isLoadingAction(false)
            catchError(error);
        }
    }


    

    const deleteContenido = async (id) => {
        isLoadingAction(true)
        try {
            const params = {
                eliminado: true
            }
            await Fetch('kit-bienvenidas/' + id, 'put', params)
            getContenido()
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    function RenderAlert(title, id) {
        AlertConfirm3(title, 'Para eliminarlo definitivamente o recuperarlo deberás ir a la sección "Por eliminar".','question', (response) => {
            if (response.isConfirmed) {
                deleteContenido(id)
            }
        })
    }

    const handlerOnOff = async (prmId, value) => {
        isLoadingAction(true)
        try {
            const params = {
                onOff: value
            }
            await Fetch('kit-bienvenidas/' + prmId, 'put', params)
            getContenido()
            isLoadingAction(false)
        } catch (error) {
            isLoadingAction(false)
            catchError(error);
        }
    }

    const onReorder = async (e) => {
        const visibleRows = e.component.getVisibleRows();
        const newData = [...contenido];
        const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
        const fromIndex = newData.indexOf(e.itemData);

        newData.splice(fromIndex, 1);
        newData.splice(toIndex, 0, e.itemData);

        setContenido(newData);
        await ordenContenido(newData)
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    function OnOff(data) {
        return (
            <div class="custom-control custom-switch">
                <input type="checkbox"
                    checked={data.onOff}
                    class="custom-control-input"
                    onChange={(v) => handlerOnOff(data.id, v.target.checked)}
                    id={"csPublicado" + data.id} />
                <label role="button" class="custom-control-label" for={"csPublicado" + data.id}></label>
            </div>
        )
    }

    function Accion(data) {
        return (
            <>
                <Link to={"/kit-bienvenida/ver/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
                <button type="button" className="btn btn-sm btn btn-danger" onClick={() => { RenderAlert("Está apunto de eliminar este contenido", data.id) }} ><i className="fa fa-trash" /></button>{' '}
            </>
        )
    }

    function Slug(data) {
        return (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Hacer click para copiar url
                        </Tooltip>
                    }
                >
                    <i className='fa fa-copy fa-lg text-info' role="button"
                        onClick={() => copyToClickBoard('kit-bienvenidas/' + data.slug)}
                    ></i>
                </OverlayTrigger>
            </>
        )
    }

    const creacion=(data)=>formatDate(data?.created_at);

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            Listado de Kits de bienvenida publicados
                        </Card.Header>
                        <Card.Body>

                            <div className='table-responsive'>
                                <DataGridForm dataSource={contenido}
                                    keyExpr='id'
                                    columns={[
                                        { caption: 'Id', dataField: 'id' },
                                        { caption: 'Titulo', dataField: 'titulo' },
                                        { caption: 'Creado por', dataField: 'creado_por.username' },
                                        { caption: 'Creación',render:"creacion", cellRender:{creacion}},
                                        { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                        { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                        { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion }, hidingPriority: 0 }
                                    ]} />
                            </div>
                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaKit);