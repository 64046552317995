import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { Fetch, catchError } from "../../../utils/fetch";
import { connect } from "react-redux";
import { loadingAction } from "../../../redux/actions";
import DataGridForm from "../../../components/common/DataGridForm";
import {
  AlertConfirm,
  AlertConfirm3,
  AlertSuccess,
} from "../../../components/alert";

function LogKitsEnviados({ isLoadingAction, isLoading }) {
  const { firstDay, lastDay }=getCurrentMonthDates();
  const [contenido, setContenido] = React.useState(null);
  const [initialDate, setInitialDate] = useState(firstDay);
  const [finalDate, setFinalDate] = React.useState(lastDay);

  React.useEffect(() => {
    getContenido();
  }, []);

  // Obtener contenido inicial
  const getContenido = async () => {
    isLoadingAction(true);
        
    try {
        const { data } = await Fetch(
          `kit-bienvenidas/api/BuscarPorFecha?desde=${initialDate}&hasta=${finalDate}`,
          "get"
        ); 
      
      setContenido(data.data);
      isLoadingAction(false);
    } catch (error) {
      console.error("Error al obtener contenido:", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function getCurrentMonthDates() {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split("T")[0];
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split("T")[0];
    return { firstDay, lastDay };
  };

  
  // Reenvío múltiple
  const ReenvioMultiple = async () => {
    const query = {
      queryType: "rango",
      query: {
        desde: initialDate,
        hasta: finalDate,
      },
    };

    try {
      isLoadingAction(true);
      AlertConfirm3(
        "Se reenviaran los kits de bienvenida a todos los usuarios que coincidan con los filtros seleccionados.",
        '¿Desea continuar?',
        "question",
        async (response) => {
          if (response.isConfirmed) {
            await Fetch("kit-bienvenidas/api/ReenvioKits", "post", query);
            //alert
            AlertSuccess("Los kits de bienvenida se enviaron correctamente.");
          }
          isLoadingAction(false);
        },
        "Reenviar",
        true,
        "Cancelar"
      );
    } catch (error) {
      console.error("Error en Reenvío múltiple:", error);
      catchError(error);
      isLoadingAction(false);
    }
  };

  const ReenvioSocio = async (credencial) => {
    const query = {
      queryType: "socio",
      query: {
        credencial: credencial
      }
    };

    try {
      isLoadingAction(true);
      AlertConfirm3(
        "Se reenviará el kit de bienvenida al usuario seleccionado.",
        '¿Desea continuar?',
        "question",
        async (response) => {
          if (response.isConfirmed) {
            await Fetch("kit-bienvenidas/api/ReenvioKits", "post", query);
            AlertSuccess("El kit de bienvenida se envio correctamente.");
          }
          isLoadingAction(false);

        },
        "Reenviar",
        true,
        "Cancelar"
      );
    } catch (error) {
      console.error("Error en Reenvío múltiple:", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function Accion(data) {
    return (
      <>
        <button className="btn btn-sm btn-success" onClick={() => ReenvioSocio(data.contrato)}>
          <i className="fa fa-share" />
        </button>
      </>
    );
  }

  const handleInitialDateChange = (event) => {
    setInitialDate(event.target.value);
  };

  const handleFinalDateChange = (event) => {
    setFinalDate(event.target.value);
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Listado de socios con kit de bienvenida en el periodo vigente</Card.Header>
            <Card.Header>
              <div className="float-right mt-3">
                <button
                  className="btn btn-primary btn-rounded box-shadow btn-icon"
                  onClick={ReenvioMultiple}
                >
                  <i className="fa fa-share" /> Reenvío múltiple
                </button>
              </div>
              <div className="d-flex align-items-end mb-3  gap-custom-kit" >
                <div className="d-flex flex-column">
                  <label className="mb-1">Fecha desde</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="DD/MM/AAAA"
                    style={{ width: "120px" }}
                    value={initialDate}
                    onChange={handleInitialDateChange}
                  />
                </div>
                <div className="d-flex flex-column">
                  <label className="mb-1">Fecha hasta</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="DD/MM/AAAA"
                    style={{ width: "120px" }}
                    value={finalDate}
                    onChange={handleFinalDateChange}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                  style={{ height: "38px" }}
                  onClick={getContenido}
                >
                  <i className="fa fa-search me-2" style={{marginRight: 10}} /> Buscar
                </button>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  keyExpr="contrato"
                  columns={[
                    { caption: "ID Contrato", dataField: "contrato" },
                    { caption: "Nombre Titular", dataField: "nombre" },
                    { caption: "E-Mail", dataField: "email" },
                    { caption: "Fecha de alta", dataField: "fechaAlta" },
                    {
                      caption: "Acción",
                      alignment: "center",
                      render: "Accion",
                      cellRender: { Accion },
                      hidingPriority: 0,
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogKitsEnviados);
