/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { Formik, FieldArray } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import ModalComponent from "../../../components/modal";
import { validate } from "../validationSchema";
import { EstadoContenido } from "../../../utils/constants";
import { eliminarImagen, removeSpecial } from "../../../utils/tools";
import LayoutJodit from "../../../components/customizer/layout-jodit";
const VerPlankitX = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  //const canPublish = permissions.planes.publicar.enabled;
  const [formValues, setFormValues] = React.useState({});
  const [showModalColores, setShowModalColores] = React.useState(false);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  const [promosPlan, setPromosPlan] = React.useState([]);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: process.env.REACT_APP_BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];

          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  React.useEffect(() => {
    if (id) {
      getplanesById();
    }
  }, []);

  const getplanesById = async (values) => {
    // ?_publicationState=preview&published_at_null=true
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        `kit-bienvenidas/${id}?_publicationState=preview&published_at_null=true`,
        "get"
      );
      setFormValues(data);
      /* const promociones = data.promociones
        .map((item) => item.promocion)
        .filter((item) => item != null); */
      /* setPromosPlan(promociones); */
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    values.published_at = new Date();
    values.estado = EstadoContenido.Publicado;
    values.promociones = promosPlan.map((item) => {
      return { promocion: item.id };
    });

    values.informacion = removeSpecial(values.informacion);

    dispatch(loadingAction(true));

    const idPadre = values.idPadre;
    const idToApi = idPadre ? idPadre : id;

    try {
      const { data } = await Fetch(
        "kit-bienvenidas/aprobar/" + idToApi,
        "put",
        values
      );

      dispatch(loadingAction(false));

      AlertSuccess("Plan actualizado con éxito");

      history.push("/kit-bienvenida/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async () => {
    dispatch(loadingAction(true));

    try {
      const params = {
        published_at: null,
        estado: EstadoContenido.EnRevision,
      };

      const { data } = await Fetch("kit-bienvenidas/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la publicación del plan con éxito");

      history.push("/kit-bienvenida/lista-borradores");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const handleBlurTitulo = async (event, fn) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::kit-bienvenidas.kit-bienvenidas",
      field: "slug",
      data: {
        titulo: value,
        slug: "",
      },
    };
    fn(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      fn("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data, field, fn) => {
    fn(field, data, false);
  };

  const initialValues = {
    titulo: "",
    descripcion: "",
    /*  slug: "",
        miniatura: "",
        fondo: "",
        titulo: "",
        informacion: "",
        botones: [
            {
                nombre: "",
                url: "",
                estilo: "color1"
            }
        ],

        color: "color1",
        locale: "es", */
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validate}
        onSubmit={(values, { setSubmitting }) => {
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setValues,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <ModalComponent
                  isShow={showModalColores}
                  setShowModal={setShowModalColores}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Nuevo Kit de Bienvenida</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Titulo</label>
                          <input
                            type="text"
                            name="titulo"
                            onChange={handleChange}
                            className="form-control"
                            value={values.titulo}
                          />
                          {errors && errors.titulo && (
                            <div className="text-danger">{errors.titulo}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                        <Form.Group>
                          <label>Contenido</label>
                          <LayoutJodit
                            setLayoutJodit={setLayoutJodit}
                            layoutJodit={layoutJodit}
                          />
                          <JoditEditor
                            config={config}
                            value={values.descripcion}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) =>
                              handleChangeJoiEditor(
                                newContent,
                                "descripcion",
                                setFieldValue
                              )
                            }
                          />
                          {errors && errors.descripcion && (
                            <div className="text-danger">
                              {errors.descripcion}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col className="col col-lg-2">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading}
                          className="btn btn-success btn-icon"
                        >
                          <i className="fa fa-floppy-o" />
                          Publicar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onCancelar()}
                          disabled={isLoading}
                          className="btn btn-primary btn-icon"
                        >
                          <i className="fa fa-undo " />
                          Por revisar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default VerPlankitX;
