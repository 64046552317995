import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { Formik, FieldArray } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import ModalComponent from "../../../components/modal";
import { validate } from "../validationSchema";
import { useHistory } from "react-router-dom";
import { EstadoContenido } from "../../../utils/constants";
import LayoutJodit from "../../../components/customizer/layout-jodit";

const NuevoPlan = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const [showModalColores, setShowModalColores] = React.useState(false);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  //const canPublish = permissions.planes.publicar.enabled;
  const [formValues, setFormValues] = React.useState({});
  const [promosPlan, setPromosPlan] = React.useState([]);
 

  const config = useMemo(
    () => ({
      readonly: false,
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: process.env.REACT_APP_BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];

          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    try {
      const { data } = await Fetch("kit-bienvenidas", "post", values);
      dispatch(loadingAction(false));

      AlertInfo(
        "Kit creado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/kit-bienvenida/lista-borradores");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const initialValues = {
    titulo: "",
    descripcion: "",
  };

  const handleBlurTitulo = async (event, fn) => {
    const value = event.target.value;
    const name = event.target.name;

  };

  const handleChangeJoiEditor = (data, field, fn) => {
    fn(field, data, false);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validate}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setValues,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <ModalComponent
                  isShow={showModalColores}
                  setShowModal={setShowModalColores}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Nuevo Kit de Bienvenida</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Titulo</label>
                          <input
                            type="text"
                            name="titulo"
                            onChange={handleChange}
                            onBlur={(e) => handleBlurTitulo(e, setFieldValue)}
                            value={values?.titulo}
                            className="form-control "
                          />
                          {errors && errors.titulo && (
                            <div className="text-danger">{errors.titulo}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                        <Form.Group>
                          <label>Contenido</label>
                          <LayoutJodit
                            setLayoutJodit={setLayoutJodit}
                            layoutJodit={layoutJodit}
                          />
                          <JoditEditor
                            config={config}
                            value={values.descripcion}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) =>
                              handleChangeJoiEditor(
                                newContent,
                                "descripcion",
                                setFieldValue
                              )
                            }
                          />
                          {errors && errors?.descripcion && (
                            <div className="text-danger">
                              {errors?.descripcion}{" "}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading}
                          className="btn btn-success btn-icon mt-2"
                        >
                          <i className="fa fa-floppy-o " />
                          Guardar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default NuevoPlan;
