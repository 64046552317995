/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { Formik, FieldArray } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertSuccess,
  AlertInfo,
  AlertConfirm,
} from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import ModalComponent from "../../../components/modal";
import { validate } from "../validationSchema";
import { EstadoContenido } from "../../../utils/constants";
import { removeSpecial } from "../../../utils/tools";

const VerEliminarKit = () => {
  const { id, eliminar } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const [showModalColores, setShowModalColores] = React.useState(false);
  //const canPublish = permissions.planes.publicar.enabled;
  const [formValues, setFormValues] = React.useState({});

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      disabled: true,
    }),
    []
  );

  React.useEffect(() => {
    if (id) {
      getplanesById();
    }
  }, [id]);

  const getplanesById = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch(`kit-bienvenidas/${id}`, "get");
      setFormValues(data);

      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch("kit-bienvenidas/" + id, "delete", values);

      dispatch(loadingAction(false));
      AlertSuccess("Kit eliminado con éxito");

      history.push("/kit-bienvenida/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const handleBlurTitulo = async (event, fn) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::kit-bienvenidas.kit-bienvenidas",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    fn(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      fn("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data, field, fn) => {
    fn(field, data, false);
  };

  const initialValues = {
    titulo: "",
    descripcion: "",
  };

  const onCancelar = async (values2, type) => {
    dispatch(loadingAction(true));

    try {
      const params = {
        eliminado: false,
      };

      if (formValues.estado == EstadoContenido.EnRevision) {
        params.published_at = null;
      }

      const { data } = await Fetch("kit-bienvenidas/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la eliminación del kit con éxito");

      if (formValues.estado == EstadoContenido.EnRevision) {
        history.push("/kit-bienvenida/lista-borradores");
      } else {
        history.push("/kit-bienvenida/lista");
      }
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validate}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <ModalComponent
                  isShow={showModalColores}
                  setShowModal={setShowModalColores}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Información del plan</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Título</label>
                          <input
                            type="text"
                            disabled
                            name="titulo"
                            onChange={handleChange}
                            className="form-control"
                            value={values.titulo}
                          />
                          {errors && errors.titulo && (
                            <div className="text-danger">{errors.titulo}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Contenido</label>
                          <JoditEditor
                            config={config}
                            value={values.descripcion}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) =>
                              handleChangeJoiEditor(
                                newContent,
                                "descripcion",
                                setFieldValue
                              )
                            }
                          />
                          {errors && errors.descripcion && (
                            <div className="text-danger">
                              {errors.descripcion}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mt-5">
                      <Col className="col col-lg-2">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading}
                          className="btn btn-danger btn-icon"
                        >
                          <i className="fas fa-trash" />
                          Eliminar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onCancelar()}
                          disabled={isLoading}
                          className="btn btn-primary btn-icon"
                        >
                          <i className="fas fa-undo" />
                          Restaurar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default VerEliminarKit;
