/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { FetchApi, FetchApiElastic } from "../../../../utils/fetch";
import "./logs-actividad.scss";
import TiposLog from "../../../../data/tipo-log.json";
import { Formik } from "formik";
import { validateModel } from "./validationSchema";
import CustomStore from "devextreme/data/custom_store";
import MonthPickerInput from "react-month-picker-input";
import Pagination from "../../../../utils/Pagination.js";
import moment from "moment-timezone";
require("react-month-picker-input/dist/react-month-picker-input.css");

function LogsActividad({ isLoadingAction, isLoading }) {
  const [state, setState] = React.useState({
    description: "",
    monthYear: new Date().toISOString(),
    idType: 0,
    random: Math.random(),
  });
  const [contenido, setContenido] = React.useState(null);
  const [totalData, setTotalData] = React.useState(null);
  const [isData, setIsData] = React.useState(false);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  // Para poder consultar los tipos de logs desde la BD
  const [tipoLog, setTipoLog] = useState([])

   // Estado que me va a servir para la paginación
   const [pagination, setPagination] = useState({
    currentPage: 1, // Página inicial
    totalRecords: 0, // Total de registros (lo actualizas al obtener los datos)
    totalPages: 0, // Total de páginas (lo calculas dinámicamente)
  });
  
  const formatDate = (dateString) => {
    // Parseamos la fecha respetando el offset que viene en el string
    const date = moment.parseZone(dateString);
    
    // Formateamos manteniendo la zona horaria original
    return date.format("DD/MM/YYYY HH:mm:ss");
  };

  // Función para obtener el último día del mes
  const getLastDayOfMonth = (year, month) => {
    // El mes en new Date() va de 0-11, y al poner día 0 nos da el último día del mes anterior
    return new Date(year, month, 0).getDate();
  };

  // Función para generar el rango de fechas
  const generateDateRange = (year, month) => {
    const lastDay = getLastDayOfMonth(year, month);
    return {
      startDate: formatDate(`${year}/${month}/01`),
      endDate: formatDate(`${year}/${month}/${lastDay}`)
    };
  };

  // Utilizamos useEffect para establecer el rango inicial
  useEffect(() => {
    const initialDateRange = generateDateRange(selectedYear, selectedMonth);
    setDateRange(initialDateRange);
  }, []); // Se ejecuta solo al montar el componente

  useEffect(() => {
    fetchTypeLogs()
  }, [])

  const fetchTypeLogs = () => {
      try {
        FetchApiElastic(
          `api/LogPortal/GetTipoLogCMSGO`,
          "GET",
        )
          .then((response) => {
            setTipoLog(response.data.data);
          })
          .finally(() => {
            isLoadingAction(false);
          });
      } catch (error) {
        console.log(`Error al consultar los tipos de logs: ${error}`);
      }
  }
  
  const getContenido = async (values) => {
    const { page, pageSize } = values;
    if (values !== null) {
      isLoadingAction(true);
      setContenido(
        new CustomStore({
          key: "idLog",
          load(loadOptions) {
            isLoadingAction(true);
            const resultado = FetchApiElastic(
              `api/LogPortal/GetLogs`,
              "post",
              {
                source: "pshb",
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                idLog: values?.idType == 0 ? null : values?.idType,
                filter: values.description,
                page: page || pagination.currentPage
              }
            )
              .then((response) => {
                // Obtenemos el total de registros para crear la paginación
                let total_data = response?.data?.data.totalCount ?? 0;
                setTotalData(total_data)

                const formattedData = response?.data?.data.items.map((item) => ({
                  ...item,
                  date: formatDate(item.date),
                })) ?? [];


                // Condicional que permite mostrar la paginación al inicializar componente
                if (total_data > 0) setIsData(true); else setIsData(false);

                //Formula: total registros/registros por página
                var totalPagina = Math.ceil(total_data / 10);

                setPagination(prev => ({
                  ...prev,
                  totalPages: totalPagina,
                  totalRegistros: total_data
                }));
                
                return {
                  data: formattedData,
                  totalCount: response?.data?.data?.totalCount,
                };
              })
              .finally(() => {
                isLoadingAction(false);
              });
            return resultado;
          },
        })
      );
    }
  };

  const downloadExcel = async (values) => {
    const { data } = await FetchApi(
      `log/Api/v1/LogData/GetLogActivityExcel`,
      "post",
      {
        monthYear: state.monthYear,
        description: values.description,
        pageIndex: 1,
        pageSize: totalData,
      }
    );

    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.data.file}`;
    const downloadLink = document.createElement("a");
    const fileName = "logsActividades.xlsx";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={state}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          getContenido(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>Ultimos logs del sistema</Card.Header>
                  <Card.Body>
                    <Row className="mb-15">
                      <Col md="2">
                        <label>Mes</label>
                        <MonthPickerInput
                          lang="es"
                          year={new Date().getFullYear()}
                          month={new Date().getMonth()}
                          onChange={function (
                            maskedValue,
                            selectedYear,
                            selectedMonth
                          ) {
                            const newMonth = selectedMonth + 1;
                            
                            // Actualizamos los estados del mes y año
                            setSelectedMonth(newMonth);
                            setSelectedYear(selectedYear);
                            
                            // Calculamos el primer y último día del mes
                            const lastDay = getLastDayOfMonth(selectedYear, newMonth);
                            
                            // Creamos el rango de fechas
                            const newDateRange = {
                              startDate: formatDate(`${selectedYear}/${newMonth}/01`),
                              endDate: formatDate(`${selectedYear}/${newMonth}/${lastDay}`)
                            };
                            
                            // Actualizamos el estado del rango
                            setDateRange(newDateRange);
                            
                            setState({
                              ...state,
                              monthYear:
                                "01/" +
                                (selectedMonth + 1)
                                  .toString()
                                  .padStart(2, "00") +
                                "/" +
                                selectedYear,
                            });
                          }}
                        />
                      </Col>
                      <Col md="2">
                        <label>Tipo log.</label>
                        <select
                          class="form-control"
                          name="idType"
                          onChange={handleChange}
                          value={values?.idType}
                        >
                          <option selected="selected" value="">
                            Seleccione uno
                          </option>
                          <option selected="selected" value="0">
                            Todos
                          </option>
                          {tipoLog.map((item, index) => {
                            return (
                              <option value={item.id}>{item.text}</option>
                            );
                          })}
                        </select>
                        <div className="text-danger">{errors?.idType}</div>
                      </Col>
                      <Col md="2">
                        <label>Descripción</label>
                        <input
                          type="text"
                          class="form-control"
                          name="description"
                          onChange={handleChange}
                          value={values?.description}
                        />
                      </Col>
                      <Col md="2" className="mt-31">
                        <label>&nbsp;</label>
                        <Button onClick={handleSubmit}>
                          <i className="fa fa-search mr-2"></i>Buscar
                        </Button>
                      </Col>
                    </Row>

                    <div className="table-responsive">
                      <DataGridForm
                        dataSource={contenido}
                        // remoteOperations={true}
                        columns={[
                          // { caption: "Fecha", dataField: "fecha" },
                          // { caption: "Descripción", dataField: "description" },
                          // { caption: "Código", dataField: "code" },
                          // { caption: "Doctor", dataField: "doctor" },
                          { caption: "Fecha", dataField: "date", width:150 },
                          { caption: "Email", dataField: "email", width:220 },
                          { caption: "Dni", dataField: "dni", width:140 },
                          { caption: "Credencial", dataField: "credencial", width:150 },
                          { caption: "Descripción", dataField: "description"},
                        ]}
                      />
                      {contenido && (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => downloadExcel(values)}
                        >
                          <i className="fa fa-file-excel-o" /> Exportar excel
                        </button>
                      )}
                    </div>
                  {isData ?  
                  <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                    <Pagination 
                      currentPage={pagination.currentPage} 
                      totalPages={pagination.totalPages} 
                      onPageChange={(page) => { 
                        setPagination((prev) => ({ ...prev, currentPage: page })); 
                        getContenido({
                          ...values, // Tus valores actuales
                          page: page, // Pasa la nueva página seleccionada
                        });
                      }
                    } 
                    />
                    </div>
                  : ''}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsActividad);
